import * as constants from "./actionTypes";
import * as common from "./commonActions";
import { isValidEmail } from '../utility/helperFunctions'
import { objectToFormData } from '../utility/formHelper'
import { handleAPIError } from '../utility/actionHelper'

import Axios from '../axios'

export function successRequestingFamilies(response) {
  if (response.status === 200) {
    return { type: constants.ADD_FAMILIES, families: response.data.families };
  } else {
    console.log("ERROR ON ADDING FAMILY");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingFamily(response) {
  if (response.status === 200) {
    return { type: constants.ADD_FAMILY, family: response.data.family };
  } else {
    console.log("ERROR ON ADDING FAMILY");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingFamilyStats(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_FAMILY_STATS,
      family_stats: response.data.stats
    };
  } else {
    console.log("ERROR ON ADDING FAMILY");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successUpdatingFamily(response) {
  if (response.status === 200 || response.status === 201) {
    return { type: constants.UPDATE_FAMILY, family: response.data.family };
  } else {
    console.log("ERROR ON UPDATING FAMILY");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export const successAddingFamilyMember = response => {
  let user_division = response.data.user.family.users.find( el =>
    el.id === response.data.user.id
  ).current_division
  return {
    type: constants.ADD_FAMILY_MEMBER,
    user: {
      ...response.data.user,
      current_division: user_division
    }
  };
}

export function successAddingFamilyEnrollments(response) {
  if (response.status === 200 || response.status === 201) {
    return {
      type: constants.ADD_FAMILY_ENROLLMENTS,
      enrollments: response.data.enrollments
    };
  } else {
    console.log("ERROR ON ADDING FAMILY ENROLLMENTS");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successAddingFamilyMemberEnrollment(response) {
  if (response.status === 200 || response.status === 201) {
    return {
      type: constants.ADD_FAMILY_ENROLLMENT,
      enrollment: response.data.enrollment
    };
  } else {
    console.log("ERROR ON ADDING FAMILY ENROLLMENT");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successInvitingFamily(response) {
  if (response.status === 200 || response.status === 201) {
    return {
      type: constants.ADD_INVITATION,
      invitation: response.data.invitation
    };
  } else {
    console.log("ERROR ON ADDING FAMILY ENROLLMENT");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRemindingFamily(response) {
  if (response.status === 200 || response.status === 201) {
    return {
      type: constants.SEND_FAMILY_REMINDER,
      reminder: response.data.reminder
    };
  } else {
    console.log("ERROR REMINDING FAMILY");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function getFamilies(community_id) {
  return function(dispatch) {
    // BODY OF THUNK

    // TURN SPINNER ON...
    dispatch(common.loadingChanged(true));
    // FETCH API CALL FROM SERVER THEN DISPATCH UI ACTION
    // THEN TURN SPINNER OFF
    return requestFamilies(community_id)
      .then(response => dispatch(successRequestingFamilies(response)))
      .then(turnOffSpinner => dispatch(common.loadingChanged(false)));
  };
}

export function getFamily(family_id) {
  return function(dispatch) {
    // BODY OF THUNK

    // TURN SPINNER ON...
    dispatch(common.loadingChanged(true));
    // FETCH API CALL FROM SERVER THEN DISPATCH UI ACTION
    // THEN TURN SPINNER OFF
    return requestFamily(family_id)
      .then(response => dispatch(successRequestingFamily(response)))
      .then(turnOffSpinner => dispatch(common.loadingChanged(false)));
  };
}

export function getFamilyStats(family_id) {
  return function(dispatch) {
    return requestFamilyStats(family_id).then(response =>
      dispatch(successRequestingFamilyStats(response))
    );
  };
}

export function addFamilyMember(user) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    dispatch({ type: constants.UI_ERROR, error: {} });
    return registerAddFamilyMember(user)
      .then(response => dispatch(successAddingFamilyMember(response)))
      .then(() => dispatch(common.loadingChanged(false)))
      .catch(err => {
        dispatch(common.loadingChanged(false))
        dispatch({ type: constants.UI_ERROR, error: err.response.data.errors })

        throw handleAPIError(err)
      })
  };
}

export function updateFamily(family_id, family) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));

    return requestUpdateFamily(family_id, family)
      .then(response => dispatch(successUpdatingFamily(response)))
      .then(turnOffSpinner => dispatch(common.loadingChanged(false)))
      .catch(err => {
        dispatch(common.loadingChanged(false));
        throw err;
      });
  };
}

export function getFamilyEnrollments(family_id, user_id, status) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));

    return requestFamilyEnrollments(family_id, user_id, status)
      .then(response => dispatch(successAddingFamilyEnrollments(response)))
      .then(turnOffSpinner => dispatch(common.loadingChanged(false)));
  };
}

export function addFamilyMemberEnrollment(user_id, course_id) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));

    return requestAddFamilyEnrollment(user_id, course_id)
      .then(response => dispatch(successAddingFamilyMemberEnrollment(response)))
      .then(turnOffSpinner => dispatch(common.loadingChanged(false)));
  };
}

export function inviteFamily(family, community_id, coach_id, url, lead_id) {
  return function(dispatch) {
    return requestInviteFamily(
      family,
      community_id,
      coach_id,
      url,
      lead_id
    ).then(response => dispatch(successInvitingFamily(response)));
  };
}

export function sendFamilyReminder(invitation, community_id, coach_id, url) {
  return function(dispatch) {
    return requestFamilyReminder(invitation, community_id, coach_id, url).then(
      response => dispatch(successRemindingFamily(response))
    );
  };
}
// API CALLS
function requestFamilies(community_id) {
  let requestUrl = "/api/v1/communities/" + community_id + "/families";
  return Axios.get(requestUrl);
}

function requestFamily(family_id) {
  let requestUrl = "/api/v1/families/" + family_id;
  return Axios.get(requestUrl);
}

function requestFamilyStats(family_id) {
  let requestUrl = "/api/v1/families/" + family_id + "/stats";
  return Axios.get(requestUrl);
}

function registerAddFamilyMember({ username, ...newUser }) {
  const requestUrl = '/api/v1/users'
  const familyMember = { ...newUser }

  if (isValidEmail(username)) {
    familyMember.email = username
  } else {
    familyMember.username = username
  }

  return Axios.post(requestUrl, objectToFormData(familyMember))
}

function requestFamilyEnrollments(family_id, user_id, status) {
  const requestUrl = `api/v1/families/${family_id}/enrollments`

  return Axios.get(requestUrl, { params: { user_id, status } })
}

function requestAddFamilyEnrollment(user_id, course_id) {
  let requestUrl = "/api/v1/enrollments";
  return Axios.post(requestUrl, {
    user_id: user_id,
    course_id: course_id,
    status: "applied"
  });
}

function requestInviteFamily(family, community_id, coach_id, url, lead_id) {
  let requestUrl = "/api/v1/families/invite";
  let data = {
    email: family.email,
    first_name: family.first_name,
    last_name: family.last_name,
    community_id: community_id,
    coach_id: coach_id,
    url,
    new_web: true
  };
  if (lead_id) {
    data.lead_id = lead_id;
  }
  return Axios.post(requestUrl, data);
}

function requestFamilyReminder(invitation, community_id, coach_id, url) {
  let requestUrl = "/api/v1/families/send_reminder";
  return Axios.post(requestUrl, {
    email: invitation.email,
    invitation_id: invitation.id,
    community_id: community_id,
    coach_id: coach_id,
    url,
    new_web: true,
  });
}

function requestUpdateFamily(family_id, family) {
  let requestUrl = "/api/v1/families/" + family_id;

  var data = new FormData();

  for (var key in family) {
    if (family.hasOwnProperty(key) && family[key] !== "") {
      data.append(key, family[key]);
    }
  }

  return Axios.put(requestUrl, data);
}
