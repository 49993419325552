import React from "react";
//redux bits
import { connect } from "react-redux";
import store from "../../../store/store";
import { bindActionCreators } from "redux";
import * as familyActions from "../../../actions/familyActions";
import { displayYearOptions } from "../../../utility/helperFunctions";

import isEmpty from "../../../utility/isEmpty";
import TextInput from "../../common/form/TextInput";
import _ from "lodash";
import moment from "moment";

// Constants
import CONSENTS from "../../../constants/consentDocuments.json";

// Childrens
import COPPAConsentNotice from "../../consent/COPPAConsentNotice";

const GENDERS = [
  { value: 1, title: "Male" },
  { value: 2, title: "Female" },
  { value: 3, title: "Other" },
  // { value: 0, title: "Prefer not to say" }
];

class StudentForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      first_name: "",
      last_name: "",
      username: "",
      age: 0,
      gender: "", // Using https://en.wikipedia.org/wiki/ISO/IEC_5218 as standard for gender
      password: "",
      birth_month: "",
      birth_day: "",
      birth_year: "",
      password_confirmation: "",
      overTwelve: "",
      hasCOPPAConsent: false,
    };
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  displayGenderOptions = () => {
    return GENDERS.map((gender, i) => (
      <option key={gender.value} value={gender.value}>
        {gender.title}
      </option>
    ));
  };

  submitForm = () => {
    const { hasCOPPAConsent, ...formState } = this.state;
    const birthdate = moment()
      .date(formState.birth_day)
      .month(formState.birth_month - 1)
      .year(formState.birth_year)
      .toDate();
    const family_id = this.props.family.id;

    const student = { ...formState, birthdate, family_id };

    if (hasCOPPAConsent) {
      const consent = {
        document_consented: "coppa_policy",
        consented_on: new Date(),
        version: CONSENTS.coppa_policy.version,
        proxy_consent: true,
        proxy_consent_given_by: this.props.currentUser.id,
      };

      student.proxy_consent_given = JSON.stringify(consent);
    }
    this.props.family_actions
      .addFamilyMember(student, this.props.closeModal)
      .then((res) => {
        if (res) {
          this.props.closeModal();
        }
      }).catch(() => {})
  };

  getConsent = (e) => this.setState({ hasCOPPAConsent: e.target.checked });

  handleSubmit = (e) => {
    e.preventDefault();
    store.dispatch({ type: "UI_ERROR", error: {} });
    const { ...formState } = this.state;

    let error = {};
    for (const key in formState)
      if (isEmpty(formState[key])) error[key] = ["Required"];
    if (formState.password !== formState.password_confirmation)
      error.password_confirmation = ["Passwords must match"];

    if (!isEmpty(error)) return store.dispatch({ type: "UI_ERROR", error });
    // else if (overTwelve === 'false' && !hasCOPPAConsent) return this.interceptForCOPPA();
    else return this.submitForm();
  };

  render() {
    const needsCOPPAConsent =
      this.state.overTwelve === "false" && !this.state.hasCOPPAConsent;
    const disableAddFamilyMember = this.props.isLoading || needsCOPPAConsent;

    return (
      <form id='student-form' className='form-container pt-3 col-md-6 mx-auto'>
        <div className='row'>
          <div className='col-md-6 mb-2'>
            <label>First Name</label>
            <TextInput
              name='first_name'
              type='text'
              label=''
              onChange={this.handleChange}
              placeholder='First Name'
              value={this.state.first_name}
              error={this.props.errors.first_name}
            />
          </div>
          <div className='col-md-6 mb-2'>
            <label>Last Name</label>
            <TextInput
              name='last_name'
              type='text'
              label=''
              onChange={this.handleChange}
              placeholder='Last Name'
              value={this.state.last_name}
              error={this.props.errors.last_name}
            />
          </div>
          <div className='col-md-6 mb-2'>
            <label>Username</label>
            <TextInput
              name='username'
              type='text'
              label=''
              onChange={this.handleChange}
              placeholder='Username'
              value={this.state.username}
              error={this.props.errors.username || this.props.errors.email}
              autoComplete='username'
            />
          </div>

          <div
            className={`mb-2 ${
              this.props.getMinorityStatus ? "col-md-6" : "col-md-3"
            }`}
          >
            <label>Date of Birth</label>
            <div className='row'>
              <div className='col-4'>
                <h6>Month</h6>
                <select
                  onChange={this.handleChange}
                  name='birth_month'
                  value={this.state.birth_month}
                  className={`form-control ${
                    this.props.errors.birth_month ? " is-invalid" : ""
                  }`}
                >
                  <option value='' disabled>
                    Month
                  </option>
                  {_.range(1, 13).map(function (x) {
                    return <option key={x}>{x}</option>;
                  })}
                </select>
                {this.props.errors.birth_month && (
                  <div className='alert alert-danger'>
                    {this.props.errors.birth_month}
                  </div>
                )}
              </div>
              <div className='col-4'>
                <h6>Day</h6>
                <select
                  onChange={this.handleChange}
                  name='birth_day'
                  value={this.state.birth_day}
                  className={`form-control ${
                    this.props.errors.birth_day ? " is-invalid" : ""
                  }`}
                >
                  <option value='' disabled>
                    Day
                  </option>
                  {_.range(1, 32).map((x) => {
                    return <option key={x}>{x}</option>;
                  })}
                </select>
                {this.props.errors.birth_day && (
                  <div className='alert alert-danger'>
                    {this.props.errors.birth_day}
                  </div>
                )}
              </div>
              <div className='col-4'>
                <h6>Year</h6>
                <select
                  onChange={this.handleChange}
                  name='birth_year'
                  value={this.state.birth_year}
                  className={`form-control ${
                    this.props.errors.birth_year ? " is-invalid" : ""
                  }`}
                >
                  <option value='' disabled>
                    Year
                  </option>
                  {displayYearOptions()}
                </select>
                {this.props.errors.birth_year && (
                  <div className='alert alert-danger'>
                    {this.props.errors.birth_year}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={`mb-2 ${
              this.props.getMinorityStatus ? "col-md-6" : "col-md-3"
            }`}
          >
            <label>Gender</label>
            <select
              onChange={this.handleChange}
              value={this.state.gender}
              name='gender'
              className={`form-control ${
                this.props.errors.gender ? " is-invalid" : ""
              }`}
            >
              <option value='' disabled>
                Select Gender
              </option>
              {this.displayGenderOptions()}
            </select>
            {this.props.errors.gender && (
              <div className='alert alert-danger'>
                {this.props.errors.gender}
              </div>
            )}
          </div>

          {this.props.getMinorityStatus && (
            <div className='mb-2 col-md-6'>
              <label className='d-block'>
                Is this member over 12 years old?
              </label>
              <div className='form-check-inline ml-3'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='overTwelve'
                  id='under-12-yes'
                  onChange={this.handleChange}
                  value={true}
                />
                <label className='form-check-label' htmlFor='under-12-yes'>
                  Yes
                </label>
              </div>
              <div className='form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='overTwelve'
                  id='under-12-no'
                  onChange={this.handleChange}
                  value={false}
                />
                <label className='form-check-label' htmlFor='under-12-no'>
                  No
                </label>
              </div>
            </div>
          )}

          <div className='col-12 mb-2'>
            <label>Password</label>
            <TextInput
              name='password'
              type='password'
              label=''
              onChange={this.handleChange}
              placeholder='Password'
              value={this.state.password}
              error={this.props.errors.password}
              autoComplete='new-password'
            />
          </div>
          <div className='col-12 mb-2'>
            <label>Confirm Password</label>
            <TextInput
              name='password_confirmation'
              type='password'
              label=''
              onChange={this.handleChange}
              placeholder='Confirm Password'
              value={this.state.password_confirmation}
              error={this.props.errors.password_confirmation}
              autoComplete='new-password'
            />
          </div>
        </div>
        <div className='w-100 d-flex justify-content-center align-items-center'>
          <button
            id='submit-btn'
            onClick={this.handleSubmit}
            type='submit'
            className='btn btn-primary mt-5'
            disabled={disableAddFamilyMember}
          >
            {this.props.isLoading
              ? "Adding Family Member..."
              : "Add Family Member"}
          </button>
        </div>

        {this.state.overTwelve === "false" && (
          <COPPAConsentNotice getConsent={this.getConsent} />
        )}
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.current_user,
  family: state.family,
  errors: state.ui.error || {},
  isLoading: state.loading.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  family_actions: bindActionCreators(familyActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentForm);
