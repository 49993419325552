import * as types from '../actions/actionTypes'
import update from 'immutability-helper'

// state = [] : Start out with no accounts
export default function user(state = [], action) { // eslint-disable-line complexity
  switch (action.type) {
    case types.SIGNIN_USER:
      if (state.user && state.user.id === action.user.id) {
        return Object.assign({}, state, {
          current_user: action.user,
          user: action.user,
        })
      } else {
        return Object.assign({}, state, {
          current_user: action.user,
        })
      }

    case types.REFRESH_USER:
      if (state?.current_user?.id === action.user.id) {
        return Object.assign({}, state, {
          current_user: action.user,
          user: state?.user?.id === action.user.id ? action.user : state?.user,
        })
      }

      return []

    case types.ADD_USER:
      return Object.assign({}, state, {
        user: action.user,
      })

    case types.UPDATE_USER:
      return Object.assign({}, state, {
        user: action.user,
      })

    case types.UPDATE_CURRENT_USER:
      return Object.assign({}, state, {
        current_user: action.current_user,
      })

    case types.POST_COURSE_ACTIVITY: {
      // Add the points we got to the current_user's points
      const pointsEarned = action.activity.points
      const curPoints = state.current_user.points
      let totalPoints = curPoints
      if (pointsEarned !== undefined) {
        totalPoints = curPoints + parseInt(pointsEarned, 10)
      }

      return update(state, {
        current_user: {
          points: { $set: totalPoints },
        },
      })
    }

    case types.ADD_USER_ACTIVITIES:
      return Object.assign({}, state, {
        user_activities: action.activities,
      })

    case types.UPDATE_ACTIVITY: {
      if (!state.user_activities || action.activity.user.id !== state.user?.id) {
        return state
      }
      const activities = [...state.user_activities]
      const activityIndex = activities.findIndex(activity => activity.id === action.activity.id)
      activities[activityIndex] = action.activity
      return {
        ...state,
        user_activities: activities,
      }
    }

    case types.DELETE_ACTIVITY: {
      let userActivities = []
      const playActivities = []
      const trainActivities = []
      const stateCopy = JSON.parse(JSON.stringify(state))

      if (!stateCopy.user || stateCopy.user.id !== action.activity.user.id) {
        return state
      }

      if (action.activity.points > 0) {
        stateCopy.user.points -= action.activity.points
      }

      if (state.user_activities) {
        const user_index_delete = state.user_activities
          .map((e) => e.id)
          .indexOf(action.activity.id)
        userActivities = [...state.user_activities]
        userActivities.splice(user_index_delete, 1)
      }

      if (state.user_play_activities) {
        const play_index_delete = state.user_play_activities
          .map((e) => e.id)
          .indexOf(action.activity.id)

        const playActivities = [...state.user_play_activities]
        playActivities.splice(play_index_delete, 1)
      }

      if (state.user_train_activities) {
        const train_index_delete = state.user_train_activities
          .map((e) => e.id)
          .indexOf(action.activity.id)
        const trainActivities = [...state.user_train_activities]
        trainActivities.splice(train_index_delete, 1)
      }

      return Object.assign({}, state, {
        user_activities: userActivities,
        user_play_activities: playActivities,
        user_train_activities: trainActivities,
        user: stateCopy.user,
      })
    }

    case types.ADD_USER_PLAY_ACTIVITIES:
      return Object.assign({}, state, {
        user_play_activities: action.play_activities,
      })

    case types.ADD_USER_GOAL_ACTIVITIES:
      return Object.assign({}, state, {
        user_goal_activities: action.goal_activities,
      })

    case types.ADD_USER_TRAIN_ACTIVITIES:
      return Object.assign({}, state, {
        user_train_activities: action.train_activities,
      })

    case types.ADD_USER_OBJECTIVE_ACTIVITIES:
      return Object.assign({}, state, {
        user_objective_activities: action.objective_activities,
      })

    case types.ADD_USER_STATS:
      return Object.assign({}, state, {
        user_stats: action.stats,
      })

    case types.ADD_USER_GOALS:
      return Object.assign({}, state, {
        user_goals: action.goals,
      })

    case types.PROGRESS_RANK:
      return Object.assign({}, state, {
        current_enrollment: action.current_enrollment,
      })

    case types.COMPLETE_OBJECTIVE:
      return Object.assign({}, state, {
        user_curriculum: action.user_curriculum,
      })

    case types.REMOVE_OBJECTIVE:
      return Object.assign({}, state, {
        user_curriculum: action.user_curriculum,
      })

    case types.GET_USER_CURRICULUM:
      return Object.assign({}, state, {
        user_curriculum: action.user_curriculum,
      })

    case types.POST_ACTIVITY_LIKE: {
      const activities_post_like = state.user_activities
      if (!activities_post_like || activities_post_like.length === 0) {
        return state
      }
      const selected_index_post_like = activities_post_like
        .map((e) => e.id)
        .indexOf(action.like.activity_id)
      if (selected_index_post_like === -1) {
        return state
      }
      return update(state, {
        user_activities: {
          [selected_index_post_like]: {
            like_count: {
              $apply: x => x + 1,
            },
            is_liked: { $set: true },
          },
        },
      })
    }

    case types.DELETE_ACTIVITY_LIKE: {
      const activities_delete_like = state.user_activities
      if (!activities_delete_like || activities_delete_like.length === 0) {
        return state
      }
      const selected_index_delete_like = activities_delete_like
        .map((e) => e.id)
        .indexOf(action.activity_id)
      if (selected_index_delete_like === -1) {
        return state
      }
      return update(state, {
        user_activities: {
          [selected_index_delete_like]: {
            like_count: {
              $apply: x => x - 1,
            },
            is_liked: { $set: false },
          },
        },
      })
    }

    case types.UPDATE_USER_SEARCHED_ADDRESS: {
      return { ...state, current_user: { ...state.current_user, searched_address: action.searchedAddress } }
    }

    default:
      return state
  }
}
