import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { signOutUser } from '../../../actions/authActions'
import { getSignUpPageProgram } from '../../../actions/programActions'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import colors from '../../../assets/styles/globals.scss'

const useDataForErrors = ({ errors, course }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { selectedFamilyMember } = useSelector(state => state.programRegistration)
  const user = useSelector(state => state.user.current_user)

  const logoutUser = useCallback(e => {
    e.preventDefault()
    localStorage.setItem('redirectUrl', location.pathname)
    dispatch(signOutUser())
    navigate('/log-in')
  }, [dispatch, navigate])

  const navigateToClass = useCallback(e => {
    e.preventDefault()
    dispatch(getSignUpPageProgram(course.id))
    navigate(`/landing/${course.community.id}/class/${course.id}`)
  }, [dispatch, course.id, course.community.id, navigate])

  return useMemo(() => {
    if (!errors) {
      return null
    }
    if (errors.userNotFamilyAdmin) {
      return {
        titleText: 'Please log in as a family admin',
        body: (
          <>
            <p>
              Only family admins can register their family members for programs.
              Please use your family admin&apos;s email address and password to log in.
            </p>
            <p>
              If you don&apos;t remember your family admin email, please contact us at&#20;
              <span style={{ color: colors.secondaryColor, textDecoration: 'underline' }}>support@op36golf.com</span>&#20;
              so we can help you.
            </p>
          </>
        ),
        linkAttributes: {
          linkMessage: 'Log out',
          navigateTo: '/login',
          onClick: logoutUser,
        },
      }
    }
    if (errors.courseUnavailable) {
      return {
        titleText: 'This program is unavailable',
        body: (
          <p>
            You cannot register for this program because it was set to private or it has already finished.
          </p>
        ),
        linkAttributes: {
          linkMessage: 'Browse other programs',
          navigateTo: `/landing/${user.communities[0].id}`,
        },
      }
    }
    if (errors.enrollmentsClosed) {
      return {
        titleText: 'Registration closed',
        body: (
          <p>
            You cannot register for this program because it has reached capacity or registration is not open right now for it.
          </p>
        ),
        linkAttributes: {
          linkMessage: 'Browse other programs',
          navigateTo: `/landing/${user.communities[0].id}`,
        },
      }
    }
    if (errors.invalidFamilyMember) {
      return {
        titleText: `${selectedFamilyMember?.firstName} ${selectedFamilyMember?.lastName} has already requested to join the program`,
        body: (
          <p>
            Each family member can only request to join a program once.
          </p>
        ),
        linkAttributes: {
          linkMessage: 'Back to programs',
          navigateTo: `/landing/${user.communities[0].id}`,
        },
      }
    }
    if (errors.packageUnavailable) {
      return {
        titleText: 'The package you selected is unavailable',
        body: (
          <p>
            The package selected may have been changed or removed from the program.
          </p>
        ),
        linkAttributes: {
          linkMessage: 'Back to the program',
          navigateTo: `/landing/${course.community.id}/class/${course.id}`,
          onClick: navigateToClass,
        },
      }
    }
    if (errors.userAlreadyInACommunity) {
      return {
        titleText: 'You are already in another community',
        body: (
          <>
            <p>
              This program is for members of the&#20; <span style={{ fontWeight: '700' }}>{course.community?.name}</span> community, but you are
              currently a member of the <span style={{ fontWeight: '700' }}>{user.communities[0].name}</span> community. Currently, each Op 36
              participant can only be a member of one community and register for programs within that community.
            </p>
            <p>
              If you would like to be transferred to <span style={{ fontWeight: '700' }}>{course.community?.name}</span> or have other questions,
              please reach out to us at <span style={{ color: colors.secondaryColor, textDecoration: 'underline' }}>support@op36golf.com</span>.
            </p>
          </>
        ),
        linkAttributes: {
          linkMessage: 'Back to my community',
          navigateTo: `/landing/${user.communities[0].id}`,
          isExternal: true,
        },
      }
    }
    return {
      titleText: 'An error has occurred',
      body: (
        <p>
          There was a problem registering you to this class. Please try again.
        </p>
      ),
      linkAttributes: {
        linkMessage: 'Back to the program',
        navigateTo: `/landing/${course.community.id}/class/${course.id}`,
        onClick: navigateToClass,
      },
    }
  }, [course, errors, logoutUser, navigateToClass, selectedFamilyMember, user])
}

useDataForErrors.propTypes = {
  errors: PropTypes.shape({
    invalidFamilyMember: PropTypes.arrayOf(PropTypes.string),
    userNotFamilyAdmin: PropTypes.arrayOf(PropTypes.string),
    courseUnavailable: PropTypes.arrayOf(PropTypes.string),
    enrollmentsClosed: PropTypes.arrayOf(PropTypes.string),
    packageUnavailable: PropTypes.arrayOf(PropTypes.string),
    userAlreadyInACommunity: PropTypes.arrayOf(PropTypes.string),
  }),
  course: PropTypes.object,
}

export default useDataForErrors
