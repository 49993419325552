import { useState, useMemo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { differenceInYears } from 'date-fns'
import classNames from 'classnames'

import * as familyActions from '../../../../actions/familyActions.js'
import { serialize } from '../../../../utility/serialization'
import { ReactComponent as CirclePlusIcon } from '../../../../assets/images/common/icons/circle-plus-icon.svg'
import { ReactComponent as FavoriteIcon } from '../../../../assets/images/common/icons/favorite-icon.svg'
import { ReactComponent as EditIcon } from '../../../../assets/images/common/icons/edit-icon.svg'
import NewFamilyMemberModal from '../../../classes/ProgramRegistration/ProgramDetailsStep/NewFamilyMemberModal'
import AccountCard from '../../../account/AccountCard'
import Loader from '../../../common/Op36Loader-web.js'

import classes from './AccountTab.module.scss'

const AccountTab = () => {
  const dispatch = useDispatch()

  const family = useSelector(state => state.family)
  const user = useSelector(state => state.user.current_user)
  const [showAddFamilyMemberModal, setShowAddFamilyMemberModal] = useState(false)
  const [loader, setLoader] = useState(true)
  const [createLoader, setCreateLoader] = useState(false)

  const familyAdmin = useMemo(() => (
    family.users?.toSorted((a, b) => a.id - b.id).find(u => u.family_admin === true)
  ), [family.users])

  const familyMembers = useMemo(() => (
    family.users?.toSorted((a, b) => {
      if (a.id === familyAdmin.id || (a.family_admin && !b.family_admin)) {
        return -1
      } else if (b.id === familyAdmin.id || (b.family_admin && !a.family_admin)) {
        return 1
      }

      return a.last_name.localeCompare(b.last_name) || a.first_name.localeCompare(b.first_name)
    })
  ), [family.users, familyAdmin])

  const fetchFamily = useCallback(() => {
    setLoader(true)
    dispatch(familyActions.getFamily(user.family.id))
      .finally(() => setLoader(false))
  }, [dispatch, user.family.id])

  const handleCreateUser = (userData) => {
    const userFormData = { ...userData, avatar_attributes: userData.avatar ? { photo: userData.avatar, name: userData.avatar.name } : null }

    setCreateLoader(true)
    return dispatch(familyActions.addFamilyMember(serialize(userFormData))).finally(() => setCreateLoader(false))
  }

  useEffect(() => {
    fetchFamily()
  }, [fetchFamily])

  return (
    <div className={classNames(classes.accountTab, 'd-flex flex-column justify-content-center align-items-center')}>
      {loader ? (
        <Loader message='loading family' />
      ) : (
        <>
          <header className={classNames(classes.accountInformation, 'd-flex align-items-center flex-column')}>
            <div className={classNames(classes.accountHeader, 'd-flex align-items-center w-100')}>
              <h3 className={classes.title}>My Account</h3>

              <div className='d-flex align-items-center'>
                <FavoriteIcon className={classNames(classes.favoriteIcon, 'ml-4 mr-2')}/>
                <div className='font-weight-bold'>
                  <span className={classes.familyPoints}>{family.points}</span>pts
                </div>
              </div>


              {user.family_admin && (
                <Link to='/account' className='ml-auto'>
                  <EditIcon className={classes.editIcon} />
                </Link>
              )}
            </div>

            <section className={classNames(classes.contactInformation, 'd-flex flex-wrap justify-content-between align-items-center mt-4 w-100')}>
              <small className={classNames(classes.subtitle, 'mt-2 mr-3')}>Admin Contact Information, this will be shared with your coach.</small>

              {familyAdmin.phone && familyAdmin.phone !== 'null' && familyAdmin.phone.length > 1 && (
                <div className='mt-2 mr-3'>
                  <i className='fa fa-phone fa-flip-horizontal mr-2' />
                  <span className='font-weight-bold'>{familyAdmin.phone}</span>
                </div>
              )}

              <div className='mt-2'>
                <i className='fa fa-envelope mr-2' />
                <span className='font-weight-bold'>{familyAdmin.email}</span>
              </div>
            </section>
          </header>

          <main className={classNames(classes.familyMembersList, 'mt-3')}>
            <div className='row'>
              {familyMembers.map((u, index) => (
                <Link to={`/profile/${u.id}`} className='col-12 col-sm-6 col-md-4 col-xl-3 mb-3' key={index}>
                  <AccountCard
                    avatarUrl={u.avatar?.file_url}
                    fullName={`${u.first_name} ${u.last_name}`}
                    age={differenceInYears(new Date(), new Date(u.birthdate))}
                    familyAdmin={u.family_admin}
                    division={u.current_formal_division}
                    className={classes.familyMemberCard}
                  />
                </Link>
              ))}

              {user.family_admin && (
                <>
                  <div className='col-12 col-sm-6 col-md-4 col-xl-3 mb-3' aria-hidden='true' onClick={() => setShowAddFamilyMemberModal(true)}>
                    <article className={classNames(classes.newFamilyMemberCard, 'd-flex align-items-center')}>
                      <CirclePlusIcon className={classNames(classes.circlePlusIcon, 'mr-3')} />

                      <h3 className={classes.addMemberText}>Add New Family Member</h3>
                    </article>
                  </div>

                  <NewFamilyMemberModal
                    isOpen={showAddFamilyMemberModal}
                    onClose={() => setShowAddFamilyMemberModal(false)}
                    handleCreate={handleCreateUser}
                    loader={createLoader}
                  />
                </>
              )}
            </div>
          </main>
        </>
      )}
    </div>
  )
}

export default AccountTab
