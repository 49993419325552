import _ from 'lodash'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getRegistration } from '../../../../actions/programRegistrationActions'
import { setPreviousSteps, resetRegistration, withMaterials } from '../../../../reducers/programRegistrationReducer'

import SummaryPanel from '../SummaryPanel/SummaryPanel'
import RegistrationCompletedMaterials from './RegistrationCompletedMaterials'
import RegistrationCompletedSimple from './RegistrationCompletedSimple'
import RegistrationCompletedFooter from './RegistrationCompletedFooter'
import LoadingSpinner from '../../../common/LoadingSpinner'
import LockError from '../../../common/LockError'

import './ProgramRegistrationCompletedPage.scss'

const ProgramRegistrationCompletedPage = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const course = useSelector(state => state.programPresentation.course)
  const { status, isEmbedded, registrationStatus, courseId } = useSelector(state => state.programRegistration)
  const hasMaterials = useSelector(withMaterials)

  const repeatedGetRegistration = useCallback(() => {
    dispatch(getRegistration({ registrationId: params.registrationId }))
      .unwrap()
      .then((data) => {
        if (data.registration.status === 'pending') {
          setTimeout(repeatedGetRegistration, 1000)
        }
      })
  }, [dispatch, params.registrationId])

  useEffect(() => {
    dispatch(setPreviousSteps({ courseWithMaterials: !_.isEmpty(course.materials) }))
    repeatedGetRegistration()

    return () => {
      dispatch(resetRegistration())
    }
  }, [course.materials, dispatch, params, repeatedGetRegistration])

  return (
    <>
      {status === 'processing registration' || status === 'inactive'
        ? (
          <LoadingSpinner
            text='Processing registration...'
            classNames={{
              wrapper: 'h-100',
              spinner: 'fa-4x program-registration-spinner',
            }}
          />
        )
        : registrationStatus !== 'successful' || courseId !== course.id ? (
          <LockError
            titleText='Something went wrong'
            linkAttributes={{
              linkMessage: 'Back to my community',
              navigateTo: `/landing/${course.community.id}`,
              isExternal: true,
            }}
          >
            <p>
              We couldn&apos;t register you into the program. Please try again and contact your coach if the problem persists.
            </p>
          </LockError>
        ) : (
          <div
            id='ProgramRegistrationCompletedPage'
            className='h-100 d-flex flex-lg-row flex-column flex-grow-1 justify-content-center align-items-center'
          >
            <div className='col-lg-4 px-0 d-lg-flex align-items-lg-center summary-panel-wrapper'>
              <SummaryPanel isEmbedded={isEmbedded} />
            </div>
            <div className='h-100 col-lg-8 overflow-auto px-0 d-flex flex-column justify-content-between'>
              <div className='post-registration-wrapper h-100'>
                {hasMaterials ? <RegistrationCompletedMaterials /> : <RegistrationCompletedSimple isEmbedded={isEmbedded} />}
              </div>
              <RegistrationCompletedFooter isEmbedded={isEmbedded} />
            </div>
          </div>
        )}
    </>
  )
}

export default ProgramRegistrationCompletedPage
