import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import classNames from 'classnames'

import * as communitiesActions from '../../../../../actions/communitiesActions.js'
import { setAddress, setNearbyCommunities, setError } from '../../../../../reducers/studentDashboardReducer.js'
import { ReactComponent as SearchIcon } from '../../../../../assets/images/common/icons/search-icon.svg'
import { ReactComponent as GolferHalfIconRound } from '../../../../../assets/images/common/icons/golfer-half-icon-round.svg'
import { ReactComponent as GolfBallIconRound } from '../../../../../assets/images/common/icons/golf-ball-icon-round.svg'
import { ReactComponent as EducationIconRound } from '../../../../../assets/images/common/icons/education-icon-round.svg'
import communityMapPreview from '../../../../../assets/images/mock/communityMapPreview.webp'
import Loader from '../../../../common/Op36Loader-web.js'
import Input from '../../../../common/form/Input.js'

import classes from './AddressPicker.module.scss'

const AddressPicker = () => {
  const dispatch = useDispatch()

  const searchedAddress = useSelector(state => state.user.current_user.searched_address?.name)
  const isReset = useSelector(state => state.studentDashboard.isReset)
  const errors = useSelector(state => state.studentDashboard.errors)
  const [rawAddress, setRawAddress] = useState('')
  const [loader, setLoader] = useState(false)
  const [pageLoader, setPageLoader] = useState(true)

  const handleSubmit = useCallback((newAddress = null) => {
    setLoader(true)

    newAddress = newAddress || rawAddress
    dispatch(setAddress(newAddress))
    geocodeByAddress(newAddress)
      .then(results => {
        const lat = results[0].geometry.location.lat()
        const lng = results[0].geometry.location.lng()
        const fields = results[0].address_components
        const streetNumber = fields.find(c => c.types.includes('street_number'))?.long_name || ''
        const route = fields.find(c => c.types.includes('route'))?.long_name || ''
        const street = `${streetNumber} ${route}`
        const zipCode = fields.find(c => c.types.includes('postal_code'))?.long_name || ''
        const city = fields.find(c => c.types.includes('locality'))?.long_name || ''
        const state = fields.find(c => c.types.includes('administrative_area_level_1'))?.short_name || ''
        const country = fields.find(c => c.types.includes('country'))?.short_name || ''

        return { name: newAddress, street, zipCode, city, state, country, lat, lng }
      })
      .then(address => { dispatch(communitiesActions.successRequestingNearbyCommunities(address)); return address })
      .then(address => dispatch(communitiesActions.getNearbyCommunities(address)))
      .then(response => {
        const responseCommunities = response.payload.communities
        if (responseCommunities.length === 0) {
          dispatch(setNearbyCommunities([]))

          return
        }

        const service = new window.google.maps.DistanceMatrixService()
        const requestedLat = response.meta.arg.lat
        const requestedLng = response.meta.arg.lng
        const communitiesCoordinates = responseCommunities.map((community) => (
          new window.google.maps.LatLng(community.address.lat, community.address.lng)
        ))

        service.getDistanceMatrix(
          {
            origins: [new window.google.maps.LatLng(requestedLat, requestedLng)],
            destinations: communitiesCoordinates,
            travelMode: 'DRIVING',
            avoidHighways: false,
            avoidTolls: false,
            unitSystem: window.google.maps.UnitSystem.IMPERIAL,
          },
          (res, status) => {
            if (status === 'OK') {
              const enhancedCommunities = []

              for (let index = 0; index < responseCommunities.length; index++) {
                enhancedCommunities.push({
                  ...responseCommunities[index],
                  distance: res.rows[0].elements[index].distance,
                  duration: res.rows[0].elements[index].duration,
                })
              }

              dispatch(setNearbyCommunities(enhancedCommunities))
            } else {
              dispatch(setNearbyCommunities(responseCommunities))
            }

            setLoader(false)
          }
        )
      })
      .catch(_ => { dispatch(setError({ field: 'address', value: 'Invalid address' })); setLoader(false) })
  }, [dispatch, rawAddress])

  useEffect(() => {
    if (searchedAddress && !isReset) {
      handleSubmit(searchedAddress)
    } else {
      window.googleMapsPromise_.then(() => setPageLoader(false))
    }
  }, [searchedAddress, isReset, handleSubmit])

  return (
    <div className={classNames(classes.addressPicker, 'd-flex align-items-center justify-content-center')}>
      {pageLoader ? (
        <Loader message='loading programs' />
      ) : (
        <main className={classNames(classes.addressWrapper, 'd-flex')}>
          <SearchIcon className={classNames(classes.searchIcon, 'd-none d-sm-block mr-3')} />

          <main className={classNames(classes.programInfo, 'd-flex flex-column mr-5')}>
            <h4 className={classes.title}>Find your first Program</h4>

            <p className='mb-2'>
              Let us help you find your first program next to you. Share your zip code below and
              we&apos;ll show you the closest Op 36 Locations to you. You can change it anytime.
            </p>

            <section className='d-flex flex-column'>
              <div className='d-flex mt-2 mb-2 flex-wrap'>
                <GolferHalfIconRound className={classNames(classes.statisticsIcon, 'mr-3')} />
                <div className='d-flex flex-column'>
                  <h2 className={classes.statsCounter}>175,000</h2>
                  <h5 className={classes.statsText}>Active Participants</h5>
                </div>
              </div>

              <div className='d-flex mt-2 mb-2 flex-wrap'>
                <GolfBallIconRound className={classNames(classes.statisticsIcon, 'mr-3')} />
                <div className='d-flex flex-column'>
                  <h2 className={classes.statsCounter}>750</h2>
                  <h5 className={classes.statsText}>Global Program Locations</h5>
                </div>
              </div>

              <div className='d-flex mt-2 mb-2 flex-wrap'>
                <EducationIconRound className={classNames(classes.statisticsIcon, 'mr-3')} />
                <div className='d-flex flex-column'>
                  <h2 className={classes.statsCounter}>2,500</h2>
                  <h5 className={classes.statsText}>Active Coaches to help guide you</h5>
                </div>
              </div>
            </section>

            <div className={classNames(classes.inputWrapper, 'mt-2')}>
              <label htmlFor='address'>Address <span className='required-text'>*</span></label>

              <PlacesAutocomplete
                onChange={(value) => setRawAddress(value)}
                onSelect={(value) => setRawAddress(value)}
                value={rawAddress}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <div>
                    <Input
                      {...getInputProps({
                        className: classes.addressInput,
                        placeholder: 'Enter Address',
                      })}
                      error={errors.address}
                    />

                    <div className={classNames({ [classes.dropdownContainer]: suggestions.length > 0 })}>
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          {...getSuggestionItemProps(
                            suggestion,
                            { className: classNames(classes.suggestionItem, { [classes.activeSuggestionItem]: suggestion.active }) }
                          )}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>

              <button
                className={classNames(classes.submitButton, 'btn btn-primary mt-4')}
                onClick={() => handleSubmit()}
                disabled={loader}
              >
                {loader && <i className='fa fa-spinner fa-spin mr-2' />}
                Update Location
              </button>
            </div>
          </main>

          <img className={classNames(classes.mapPreviewImage, 'd-none d-md-flex ml-auto')} src={communityMapPreview} alt='Community Map Preview' />
        </main>
      )}
    </div>
  )
}

export default AddressPicker
